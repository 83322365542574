var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-1",attrs:{"tile":""}},[_c('v-card-text',[_c('validation-observer',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'weight'),"label":_vm.$t('hr.employee.body_size.weight'),"rules":"numeric|min_value:0|max_value:999","index":0,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"decimal",rawName:"v-decimal"}],staticStyle:{"max-width":"200px"},attrs:{"min":"0","max":"999","outlined":"","dense":"","color":"tertiary","suffix":"kg","error-messages":errors},model:{value:(_vm.employeePayload.weight),callback:function ($$v) {_vm.$set(_vm.employeePayload, "weight", _vm._n($$v))},expression:"employeePayload.weight"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'height'),"label":_vm.$t('hr.employee.body_size.height'),"rules":"numeric|min_value:0|max_value:999","index":1,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"decimal",rawName:"v-decimal"}],staticStyle:{"max-width":"200px"},attrs:{"min":"0","max":"999","outlined":"","dense":"","color":"tertiary","suffix":"cm","error-messages":errors},model:{value:(_vm.employeePayload.height),callback:function ($$v) {_vm.$set(_vm.employeePayload, "height", _vm._n($$v))},expression:"employeePayload.height"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'shirtSize'),"label":_vm.$t('hr.employee.body_size.shirt_size'),"index":2,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"uppercase",rawName:"v-uppercase"}],staticStyle:{"max-width":"200px"},attrs:{"outlined":"","dense":"","color":"tertiary"},model:{value:(_vm.employeePayload.shirtSize),callback:function ($$v) {_vm.$set(_vm.employeePayload, "shirtSize", $$v)},expression:"employeePayload.shirtSize"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'pantsSize'),"label":_vm.$t('hr.employee.body_size.pants_size'),"index":3,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"uppercase",rawName:"v-uppercase"}],staticStyle:{"max-width":"200px"},attrs:{"outlined":"","dense":"","color":"tertiary"},model:{value:(_vm.employeePayload.pantsSize),callback:function ($$v) {_vm.$set(_vm.employeePayload, "pantsSize", $$v)},expression:"employeePayload.pantsSize"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'shoeSize'),"label":_vm.$t('hr.employee.body_size.shoe_size'),"rules":"min_value:0|max_value:999","index":4,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"decimal",rawName:"v-decimal"}],staticStyle:{"max-width":"200px"},attrs:{"type":"number","min":"0","max":"999","step":".5","outlined":"","dense":"","color":"tertiary","error-messages":errors},model:{value:(_vm.employeePayload.shoeSize),callback:function ($$v) {_vm.$set(_vm.employeePayload, "shoeSize", _vm._n($$v))},expression:"employeePayload.shoeSize"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'waistcoat'),"label":_vm.$t('hr.employee.body_size.waistcoat'),"rules":"max:255","index":5,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticStyle:{"max-width":"200px"},attrs:{"outlined":"","dense":"","color":"tertiary","counter":"255","error-messages":errors},model:{value:(_vm.employeePayload.waistcoat),callback:function ($$v) {_vm.$set(_vm.employeePayload, "waistcoat", $$v)},expression:"employeePayload.waistcoat"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'coat'),"label":_vm.$t('hr.employee.body_size.coat'),"rules":"max:255","index":6,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticStyle:{"max-width":"200px"},attrs:{"outlined":"","dense":"","color":"tertiary","counter":"255","error-messages":errors},model:{value:(_vm.employeePayload.coat),callback:function ($$v) {_vm.$set(_vm.employeePayload, "coat", $$v)},expression:"employeePayload.coat"}})]}}],null,true)})],1),_vm._l((_vm.employeePayload.objectAttributeValues),function(objectAttributeValue,idx){return [_c('v-col',{key:idx,staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":objectAttributeValue.value,"label":objectAttributeValue.name,"index":7 + idx,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticStyle:{"max-width":"400px"},attrs:{"outlined":"","dense":"","color":"tertiary","error-messages":errors},model:{value:(objectAttributeValue.value),callback:function ($$v) {_vm.$set(objectAttributeValue, "value", $$v)},expression:"objectAttributeValue.value"}})]}}],null,true)})],1)]})],2)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }