<template>
  <v-card class="elevation-1" tile>
    <v-card-text>
      <validation-observer slim v-slot="{ handleSubmit }">
        <v-row>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'weight')"
              :label="$t('hr.employee.body_size.weight')"
              rules="numeric|min_value:0|max_value:999"
              :index="0"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <v-text-field
                  v-model.number="employeePayload.weight"
                  min="0"
                  max="999"
                  v-decimal
                  outlined
                  dense
                  color="tertiary"
                  style="max-width: 200px;"
                  suffix="kg"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'height')"
              :label="$t('hr.employee.body_size.height')"
              rules="numeric|min_value:0|max_value:999"
              :index="1"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <v-text-field
                  v-model.number="employeePayload.height"
                  min="0"
                  max="999"
                  v-decimal
                  outlined
                  dense
                  color="tertiary"
                  style="max-width: 200px;"
                  suffix="cm"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'shirtSize')"
              :label="$t('hr.employee.body_size.shirt_size')"
              :index="2"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <v-text-field
                  v-model="employeePayload.shirtSize"
                  v-uppercase
                  outlined
                  dense
                  color="tertiary"
                  style="max-width: 200px;"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'pantsSize')"
              :label="$t('hr.employee.body_size.pants_size')"
              :index="3"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <v-text-field
                  v-model="employeePayload.pantsSize"
                  v-uppercase
                  outlined
                  dense
                  color="tertiary"
                  style="max-width: 200px;"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'shoeSize')"
              :label="$t('hr.employee.body_size.shoe_size')"
              rules="min_value:0|max_value:999"
              :index="4"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <v-text-field
                  v-model.number="employeePayload.shoeSize"
                  type="number"
                  min="0"
                  max="999"
                  step=".5"
                  v-decimal
                  outlined
                  dense
                  color="tertiary"
                  style="max-width: 200px;"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'waistcoat')"
              :label="$t('hr.employee.body_size.waistcoat')"
              rules="max:255"
              :index="5"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <v-text-field
                  v-model="employeePayload.waistcoat"
                  outlined
                  dense
                  color="tertiary"
                  style="max-width: 200px;"
                  counter="255"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'coat')"
              :label="$t('hr.employee.body_size.coat')"
              rules="max:255"
              :index="6"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <v-text-field
                  v-model="employeePayload.coat"
                  outlined
                  dense
                  color="tertiary"
                  style="max-width: 200px;"
                  counter="255"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
          <template v-for="(objectAttributeValue, idx) in employeePayload.objectAttributeValues">
            <v-col :key="idx" cols="12" class="py-0">
              <profile-field
                :value="objectAttributeValue.value"
                :label="objectAttributeValue.name"
                :index="7 + idx"
                :active-index="activeIndex"
                :saving="saving"
                :loading="loading"
                :is-editable="isEditable"
                @click:edit="onEditClick"
                @click:save="handleSubmit(onSaveClick)"
                @click:cancel="onCancelClick"
              >
                <template v-slot="{ errors }">
                  <v-text-field
                    v-model="objectAttributeValue.value"
                    outlined
                    dense
                    color="tertiary"
                    style="max-width: 400px;"
                    :error-messages="errors"
                  />
                </template>
              </profile-field>
            </v-col>
          </template>
        </v-row>
      </validation-observer>
    </v-card-text>
  </v-card>
</template>

<script>
  import { EMPLOYEE_BODY_SIZE } from "../query";

  export default {
    name: "EmployeeBodySize",
    props: {
      profileSettings: Object
    },
    components: {
      ProfileField: () => import("./components/ProfileField")
    },
    data: () => ({
      loading: false,
      employeePayload: {
        weight: null,
        height: null,
        shirtSize: null,
        pantsSize: null,
        shoeSize: null,
        coat: null,
        waistcoat: null,
        objectAttributeValues: []
      },
      employee: null,
      activeIndex: -1,
      saving: false
    }),
    computed: {
      isEditable() {
        return this.$helpers.get(this.profileSettings, this.$enums.PROFILE_SETTINGS.BODY_DIMENSIONS);
      }
    },
    methods: {
      onSaveClick() {
        this.saving = true;
        this.$api.employeeService
          .updateBodySizeCredentials(this.$store.state.auth.user.id, this.employeePayload)
          .then(response => {
            if (response.status === 200) {
              this.activeIndex = -1;
              this.fetchEmployeeBodySize();
            }
          })
          .catch(error => this.$helpers.showNotification(error.message))
          .finally(() => (this.saving = false));
      },
      onCancelClick() {
        this.activeIndex = -1;
        this.setEmployeePayload(this.employee);
      },
      onEditClick(index) {
        this.activeIndex = index;
      },
      setEmployeePayload(employee) {
        this.employeePayload = { ...employee };
        this.employeePayload.objectAttributeValues = this.$helpers.cloneDeep(employee.objectAttributeValues);
      },
      async fetchEmployeeBodySize() {
        this.loading = true;
        this.$apollo
          .query({
            query: EMPLOYEE_BODY_SIZE,
            variables: {
              id: this.$store.state.auth.user.id
            }
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              this.employee = data.employee.bodySize;
              this.setEmployeePayload(data.employee.bodySize);
            }
          })
          .catch(error => this.$helpers.showNotification(error.message))
          .finally(() => (this.loading = false));
      }
    },
    created() {
      this.fetchEmployeeBodySize();
    }
  };
</script>
